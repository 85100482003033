$(function () {
    
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    var activeLink = '';
    if (typeof $('.private-slider').attr('class') !== 'undefined') {
        var options = {
            $AutoPlay: false,
            $PauseOnHover: 1,                                //[Optional] Whether to pause when mouse over if a slider is auto playing, 0 no pause, 1 pause for desktop, 2 pause for touch device, 3 pause for desktop and touch device, 4 freeze for desktop, 8 freeze for touch device, 12 freeze for desktop and touch device, default value is 1
            $SlideWidth: 495,
            $SlideHeight: 330,
            $DragOrientation: 1,
            $SlideSpacing: 2,                          //[Optional] Orientation to drag slide, 0 no drag, 1 horizental, 2 vertical, 3 either, default value is 1 (Note that the $DragOrientation should be the same as $PlayOrientation when $DisplayPieces is greater than 1, or parking position is not 0)
            $ArrowKeyNavigation: true,   			            //[Optional] Allows keyboard (arrow key) navigation or not, default value is false
            $SlideDuration: 600,                                //Specifies default duration (swipe) for slide in milliseconds

            $ArrowNavigatorOptions: {                       //[Optional] Options to specify and enable arrow navigator or not
                $Class: $JssorArrowNavigator$,              //[Requried] Class to create arrow navigator instance
                $ChanceToShow: 1,                               //[Required] 0 Never, 1 Mouse Over, 2 Always
                $AutoCenter: 2,                                 //[Optional] Auto center navigator in parent container, 0 None, 1 Horizontal, 2 Vertical, 3 Both, default value is 0
                $Steps: 1                                       //[Optional] Steps to go for each navigation request, default value is 1
            },

            $ThumbnailNavigatorOptions: {                       //[Optional] Options to specify and enable thumbnail navigator or not
                $Class: $JssorThumbnailNavigator$,              //[Required] Class to create thumbnail navigator instance
                $ChanceToShow: 2,                               //[Required] 0 Never, 1 Mouse Over, 2 Always
                $ActionMode: 1,                                 //[Optional] 0 None, 1 act by click, 2 act by mouse hover, 3 both, default value is 1
                $Lanes: 3,                                      //[Optional] Specify lanes to arrange thumbnails, default value is 1
                $SpacingX: 30,                                   //[Optional] Horizontal space between each thumbnail in pixel, default value is 0
                $SpacingY: 28,                                   //[Optional] Vertical space between each thumbnail in pixel, default value is 0
                $DisplayPieces: 3,                             //[Optional] Number of pieces to display, default value is 1
                $ParkingPosition: 0,                          //[Optional] The offset position to park thumbnail
                $Orientation: 2                                //[Optional] Orientation to arrange thumbnails, 1 horizental, 2 vertical, default value is 1
            }
        };


        var $slider = new $JssorSlider$("private-slider", options);

        function ScaleSlider() {
            
                var parentWidth = $slider.$Elmt.parentNode.clientWidth;
                if (parentWidth)
                    $slider.$ScaleWidth(Math.max(Math.min(parentWidth, 495), 330));
                else
                    window.setTimeout(ScaleSlider, 30);
        }
        ScaleSlider();

        $(window).bind("load", ScaleSlider);
        $(window).bind("resize", ScaleSlider);
        $(window).bind("orientationchange", ScaleSlider);
    }
    
    $('.flexslider').flexslider({
        slideshow: false,
        animation: "slide",
        controlsContainer: $(".thumbs"),
        directionNav: false
    });

    $('.main-image').flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        sync: ".thumb-image"
    });

    $('.thumb-image').flexslider({
        animation: "slide",
        controlNav: false,
        directionNav: false,
        animationLoop: false,
        slideshow: false,
        itemWidth: 153,
        itemMargin: 17,
        selector: '.slides > a',
        asNavFor: '.main-image',
        start: function(slider) {
            $('.thumb-image a').on('click', function(e) {
                var count = $(this).index() + 3;
                if (count < 10) {
                    var width = $('.thumb-image a').outerWidth() + 15;
                    if ($('.thumb-image').width() < count*width) {
                        slider.animateSlides();
                    }
                }
            })
            $('.slides').show();
        }
    });
    
    $('.about-wine').on('click', function(e) {
        e.preventDefault();
        $('.table-modal').show();
    });
    
    $('.close-modal').on('click', function(e) {
        e.preventDefault();
        if ($(this).hasClass('sign-up') && activeLink !== '') {
           
            $('.link').each(function() {
                $(this).removeClass('active');
            }); 
            $(activeLink).addClass('active');
            $('.modal-fade').hide();
        } else if ($(this).hasClass('sign-up') && activeLink === '') {
            $('.link').each(function() {
                $(this).removeClass('active');
            }); 
            
            $('.modal-fade').hide();
        }
        $('.table-modal__positioner .table-modal').hide();
    });
    
    $('.sign-up-link').on('click', function(e) {
        e.preventDefault(); 
        if ($('.social-order .table-modal').is(':visible')) {
            $('.social-order .table-modal').hide();
        }
        $('fieldset input').each(function() { $(this).val('')});
        $('.help-block').each(function() { $(this).text('')});
        $('#thanks').remove();
        $('.link').each(function() {
          if ($(this).hasClass('active')) {
              activeLink = $(this);
          }
       });
       if (activeLink !== '') {
            activeLink.removeClass('active');
        }
       $(e.target).addClass('active');

       if ($('.app-toggler-btn').is(':visible')) {
           $('.app-toggler-btn').click();
       }

       $('.modal-fade:not(.success-popup)').show();
    });

    var isLoading = false;
    $('.sign-up a.right').on('click', function(e) {
        e.preventDefault();
        var self = $(this);
        $('.help-block').each(function() { $(this).text('')});
        if (!isLoading) {
            $(this).text('Sending...');
            isLoading = true;
            $.ajax({
                url: "/users",
                method: "post",
                data: $('fieldset.sign-up').serializeArray()
            }).done(function(data) {
                isLoading = false;
                $(self).text('Send');
                if (data.success) {
                    
                    $('.modal-fade:not(.success-popup)').hide();
                    $('.modal-fade.success-popup').show();

                } else {
                    if (typeof data.errors !== 'undefined') {
                        $.each(data.errors, function( attr, error ) {
                            var element = $('[name=' + attr + ']'), 
                            $group = element.closest('.field-item');

                            $group.find('.help-block').html(error).removeClass('hidden');
                        });
                    }

                }
            }).fail(function() {
                $(self).text('Send');
                isLoading = false;
            });
        }
    });
    $(document).ready(function() {
        if($('.stylish').length > 0 ){
            $('.stylish').sSelect();
        }
    });
    
    $('._shipping-billing-form input').bind('keyup blur', function(e) {
        if ($('#same_as_billing').is(':checked')) {
            var name = $(this).attr('name');
            var index = name.indexOf("shipping") === 0 ? 'billing' : 'shipping';
            $( "input[name^='"+index+"']" ).each(function() {
                var inputName = $(this).attr('name');
                if (index === 'shipping') {
                    inputName = inputName.replace("shipping", "billing");
                } else {
                    inputName = inputName.replace("billing", "shipping");
                }
                $(this).val($('[name="'+ inputName +'"]').val());
            });
           
       }
    });
    
    $('[name="shipping[state]"]').on('change', function() {
        if ($('#same_as_billing').is(':checked')) {
            $('[name="billing[state]"]').val($('[name="shipping[state]"]').val());
        }
    });
    
    $('[name="billing[state]"]').on('change', function() {
        if ($('#same_as_billing').is(':checked')) {
            $('[name="shipping[state]"]').val($('[name="billing[state]"]').val());
        }
    });
    
    $('#same_as_billing').on('change', function() {
        if($("#same_as_billing").is(":checked")){
             var fields = [
                'first_name',
                'last_name',
                'email',
                'address',
                'address2',
                'comments',
                'city',
                'state',
                'zipcode',
                'phone'
            ];
            $.each(fields, function( index, value ) {
                var valueShipping = $('[name="shipping['+ value+']"]').val();
                $('[name="billing['+ value+']"]').val(valueShipping);
            });
        }
    });
    
    $('[name="delivery"]').change(function() {
        $('._hidden-data').toggleClass('hidden');      
    });
    
    $('._delete-piece').on('click', function(e) {
       e.preventDefault();
       if ($('.order-table tbody tr').length > 1) {
           $(this).closest('tr').remove();
           var subTotal = 0;
           $('.order-table tbody tr').each(function() {
              var price = $(this).find('._price').attr('data-price'); 
              subTotal = subTotal + parseFloat(price);
           });
           $('.order-total').html('Order Sub - Total: $'+ subTotal);
       } else {
           window.location.href = '/gift-cards/shop';
       }
    });
    $('._delete-order-piece').on('click', function(e) {
       e.preventDefault();
       var path = $(this).attr('href');
       var self = this;
       $.ajax({
            url: path,
            method: "POST"
        }).done(function(data) {
            if (data.success) {
                if (data.html) {
                    $(self).closest('tr').remove();
                    $('.payments-total').replaceWith(data.html);
                } else {
                    window.location.href = '/gift-cards/shop';
                }
            }
        });  
    });
    
    $(document).on('keyup', function(e) {
        switch (e.keyCode) {
            case 27:
                if ($('.modal-fade').is(':visible')) {
                    $('.close-modal').trigger('click');
                }
                if ($('.table-modal').is(':visible')) {
                    $('.close-modal').trigger('click');
                }
                break;
            default:
                break;

        }
    });
    
    $('._short-description').readmore({
        lessLink: '<p><a href="#" class="_white-text white-btn small">CLOSE<i class="wine-icon close-icon">Icon</i></a></p>',
        moreLink: '<p><a href="#"class="_white-text white-btn small">READ MORE<i class="wine-icon">Icon</i></a></p>',
        collapsedHeight: 140
    });

    $(".app-toggler-btn").on("click", function(){
        $("body").toggleClass("app-drawer-opened");
    });

    let imageType = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

    function imageFactory (type){

        const image = document.createElement('img');
        const imageWrapper = document.createElement('div');
        const delay = imageType.indexOf(type);

        imageWrapper.classList.add("section-satisfaction__img");
        imageWrapper.appendChild(image);

        function getSrc(index) {
            return 'img/home/' + type + '-' + index + '.jpg';
        }

        let activeSrc;
        let removeImage;
        let secondSrc = getSrc(2);
        let firstSrc = getSrc(1);

        function setSrc(src){
            activeSrc = src;
            image.src = src;
        }

        function changeSrc() {
            if (removeImage) {
                $(removeImage).fadeOut(500, function () {
                    removeImage.parentNode.removeChild(removeImage);
                });
            }
            $(image).fadeIn(1000, function () {
                const timeout = Math.max(Math.floor(Math.random() * 12000), 5000) + 500 * delay;
                setTimeout(() => {
                    removeImage = image.cloneNode();
                    imageWrapper.appendChild(removeImage);
                    setSrc(activeSrc === firstSrc ? secondSrc : firstSrc);
                }, timeout);
            });
        }

        setSrc(firstSrc);
        image.onload = changeSrc;

        return {
            imageWrapper
        }
    }

    function modalFactory(modalElement){
        let slider;
        const modalRef = modalElement;
        const swiperContainerRef = modalElement.querySelector(".swiper-container");
        const modal = {
            open(){
                Object.assign(swiperContainerRef.style, {opacity: 0});

                $(modalRef).fadeIn(300, function(){
                    slider = new Swiper('#homePostersSlider', {
                        loop: true,
                        on: {
                            init: function () {
                                Object.assign(swiperContainerRef.style, {opacity: 1});
                            },
                        },
                        slidesPerView: 'auto',
                        spaceBetween: 30,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                    })
                });
            },
            close(){
                $(modalRef).fadeOut(300, function(){
                    slider.destroy(true);
                });
            }
        };

        swiperContainerRef.addEventListener("click", function(e){
            e.preventDefault();
            e.stopPropagation();
        });

        modalRef.addEventListener("click", function(e){
            modal.close();
        });

        return {
            modal
        }
    };

    $(document).ready(function () {
        const satisfactionView = document.getElementById("homePostersView");
        const satisfactionModal = document.getElementById("homePostersModal");

        if (!satisfactionView) {
            return;
        }

        let {modal} = modalFactory(satisfactionModal);
        for (let key of imageType) {
            let {imageWrapper} = imageFactory(key);
            satisfactionView.appendChild(imageWrapper);
            imageWrapper.addEventListener('click', function(){
                modal.open();
            });
        };

        var list = $('.events-list');
        var items = list.find('.list-item');
        if (!items.length) return false;
        items.each(function() {
            var self = $(this);
            var head = self.find('.list-item-head');
            var body = self.find('.list-item-body');

            head.on('click', function() {
                head.toggleClass('opened');
                body.slideToggle();
            });
        });
    });


    $('._event').on('click', function() {

        var $self = $(this);
        var event = $self.data('event');

        console.log(event);

        if (event.type !== '' && event.type !== 'default') {
            if (event.price !== '') {
                $('#_price').text(event.price);
                $('#_price').next().show();
            } else {
                $('#_price').text('Free');
                $('#_price').next().hide();
            }
            $('#_title').html(event.title);
            $('#_description').html(event.description);

            $('.table-modal').show();
        }
    });
});